import React, { useEffect, useState } from "react";
import "./competition.css";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { getCompetitions } from "../../../../../actions/landingPageActions";
import DisplayCompetitions from "../../../../../components/displaycompetitions/DisplayCompetitions.jsx";

const CompetitionSection = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const loading = state.competitions.loading;
  const { competitions } = state.competitions;
  
  useEffect(() => {
    dispatch(getCompetitions());
  }, []);
    
let totalLength=0;
  Object.keys(competitions).forEach(key => {
    const competitionGroup = competitions[key];
    totalLength += competitionGroup.length; 
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [activeView, setActiveView] = useState(() => {
    return localStorage.getItem("activeView") || "default";
  });

  const handleViewChange = (view) => {
    setActiveView(view);
    localStorage.setItem("activeView", view);
  };

  const getColor = (view) => {
    return activeView === view ? "var(--primary)" : "#c7c7c7";
  };

  if (loading) {
    return (
      <div
        className="items-container"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
      </div>
    );
  }
  return (
    <>
      {isMobile && (

        <div id="competitionSect"
        style={{display:"flex",justifyContent:"space-between",marginTop:"60px"}}>
          <h1 className="title">{totalLength} competition{totalLength>1?'s':''}</h1>
        <div className="container btn-design">
          <span onClick={() => handleViewChange("default")}>
            <Default color={getColor("default")} />
          </span>
          <span onClick={() => handleViewChange("columns")}>
            <Columns color={getColor("columns")} />
          </span>
        </div>
        </div>
      )}
      <DisplayCompetitions activeView={activeView} />
    </>
  );
};

export default CompetitionSection;

const Columns = ({ color }) => (
  <svg
    fill={color}
    width="64px"
    height="64px"
    viewBox="-24.75 -24.75 74.25 74.25"
    style={{ width: "64px", height: "64px", transform: "rotate(90deg)" }}
  >
    <path d="M0,3.875c0-1.104,0.896-2,2-2h20.75c1.104,0,2,0.896,2,2s-0.896,2-2,2H2C0.896,5.875,0,4.979,0,3.875z M22.75,10.375H2 c-1.104,0-2,0.896-2,2c0,1.104,0.896,2,2,2h20.75c1.104,0,2-0.896,2-2C24.75,11.271,23.855,10.375,22.75,10.375z M22.75,18.875H2 c-1.104,0-2,0.896-2,2s0.896,2,2,2h20.75c1.104,0,2-0.896,2-2S23.855,18.875,22.75,18.875z" />
  </svg>
);

const Default = ({ color }) => (
  <svg
    fill={color}
    width="64px"
    height="64px"
    viewBox="-516.34 -516.34 1549.02 1549.02"
  >
    <path d="M497.452,0H18.893C8.458,0,0.006,8.458,0.006,18.893v478.554c0,10.435,8.458,18.893,18.886,18.893h478.553c10.435,0,18.887-8.458,18.887-18.893V18.893C516.338,8.458,507.88,0,497.452,0z M455.138,455.133H61.206V61.2h393.932V455.133z" />
  </svg>
);
