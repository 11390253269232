import React from "react"
import { useTranslation } from "react-i18next";
import "./TotalAmount.css";
import { HashLink } from "react-router-hash-link";
const TotalAmounts = ({
  showPaymentButtons,
  setShowPaymentButtons,
  subtotal,
  discount,
  total,
}) => {
  const { i18n, t } = useTranslation();

  return (
    <div className="subtotal-section">
      <h3>
        Subtotal:&nbsp;
        <span>Lei {subtotal}</span>
      </h3>
      <h3>
        {t("Discount")}:&nbsp;
        <span>{discount}%</span>
      </h3>
      <h3>
        TOTAL:&nbsp;
        <span>Lei {total}</span>
      </h3>
      {subtotal <= 2 && (
        <>
          <p style={{ color: "red", fontSize: "1.38rem" }}>
            {t("less then 2")}
          </p>
          <HashLink smooth to="/#competitionSect">
            <button className="pro-check-btn" type="submit">
              <i class="fas fa-chevron-left"></i>
              {t("Return to Competitions")}
            </button>
          </HashLink>
        </>
      )}
    </div>
  );
};

export default TotalAmounts;
